/** @jsx jsx */
import { DUIText } from "@alch/ui";
import { memo, ReactElement } from "react";
import { jsx } from "theme-ui";
import { StringParam, useQueryParam } from "use-query-params";

import MasterLayout from "../components/MasterLayout";
import Section from "../components/Section";

const InvalidRedirectPage = memo(function InvalidRedirectPage(): ReactElement {
  const [url] = useQueryParam("url", StringParam);

  return (
    <MasterLayout title="Invalid Redirect">
      <Section>
        <DUIText size="lg">
          You have successfully logged in to Alchemy, but the URL to which you
          would normally be redirected{url ? `, ${url},` : ""} is not
          allowlisted by Alchemy's authentication servers. This may mean someone
          gave you a malicious URL in an attempt to steal your Alchemy session.
          If so, this attack has been prevented. If you think this is the result
          of an error, please contact{" "}
          <a href="mailto:support@alchemy.com">support@alchemy.com</a>.
        </DUIText>
      </Section>
    </MasterLayout>
  );
});
export default InvalidRedirectPage;
